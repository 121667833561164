.login-container {
    background-color: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 400px; /* 로그인 컨텐츠 영역을 넓힘 */
    margin: 0 auto; /* 중앙 정렬 */
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .login-form-title {
    color: var(--primary-color);
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .form-group {
    display: flex;
    align-items: center; /* 세로 중앙 정렬 */
    justify-content: space-between; /* 라벨과 입력란 사이 간격 */
  }
  
  .form-group label {
    flex: 0 0 80px; /* 라벨 너비 고정 */
    color: var(--text-color);
  }
  
  .form-group input {
    flex: 1; /* 남은 공간 모두 차지 */
    padding: 0.5rem;
    border: 1px solid var(--primary-color);
    border-radius: 4px;
  }
  
  .login-button {
    background-color: var(--accent-color);
    color: white;
    padding: 0.75rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .login-button:hover {
    background-color: var(--secondary-color);
  }
  
  .error-message {
    color: red;
    margin-top: 1rem;
  }