.header {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(87, 82, 82, 0.1);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.container-header {
  max-width: 1200px;
  margin: 0 auto;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header a {
  text-decoration: none;
}

.logo {
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: var(--primary-color);
  display: inline-block;
  margin-bottom: 15px;
}

.menu-box {
  display: flex;
  align-items: center;
}

.navbar ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.navbar ul li {
  margin-left: 10px;
  text-align: center;
}

.navbar ul li a {
  font-family: 'Noto Sans KR', sans-serif;
  text-decoration: none;
  color: var(--text-color);
  font-size: 16px;
  font-weight: 700;
  padding: 10px 15px;
  border-radius: 5px;
  transition: all 0.3s ease;
  display: block;
  background-color: var(--background-color);
}

.navbar ul li a:hover {
  color: #ffffff;
  background-color: var(--secondary-color);
}

.biz-inquiry-btn {
  font-family: 'Noto Sans KR', sans-serif;
  background-color: var(--accent-color);
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 20px;
}

.biz-inquiry-btn:hover {
  background-color: #45a049;
}

.menu-toggle {
  display: none;
}

@media (max-width: 768px) {
  .container-header {
    flex-wrap: wrap;
  }

  .logo {
    margin-bottom: 0;
  }

  .menu-box {
    display: none;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #ffffff;
    padding: 20px 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .menu-box.open {
    display: flex;
  }

  .navbar {
    width: 100%;
  }

  .navbar ul {
    flex-direction: column;
    width: 100%;
  }

  .navbar ul li {
    margin: 10px 0;
    width: 100%;
  }

  .navbar ul li a {
    padding: 15px;
    width: 100%;
    box-sizing: border-box;
  }

  .biz-inquiry-btn {
    margin: 20px 0 0 0;
    width: 100%;
  }

  .menu-toggle {
    display: block;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 1001;
  }

  .menu-toggle span {
    display: block;
    width: 25px;
    height: 3px;
    background-color: var(--primary-color);
    margin: 5px 0;
    transition: 0.3s;
  }

  .menu-toggle.open span:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  .menu-toggle.open span:nth-child(2) {
    opacity: 0;
  }

  .menu-toggle.open span:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
  }
}