.course-external-link-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .course-external-link-popup {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 90%;
    max-height: 80vh;
    overflow-y: auto;
  }
  
  .course-external-link-popup h2 {
    color: var(--primary-color);
    margin-bottom: 1rem;
    font-size: 1.5rem;
    text-align: center;
  }
  
  .course-external-schedules {
    margin-bottom: 1rem;
  }
  
  .schedule-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    padding: 0.5rem;
    background-color: #f5f5f5;
    border-radius: 4px;
    gap: 10px;
  }
  
  .schedule-info {
    display: flex;
    flex-direction: column;
  }
  
  .schedule-date {
    font-weight: bold;
    color: var(--primary-color);
  }
  
  .schedule-time {
    color: var(--secondary-color);
  }
  
  .schedule-content {
    margin-top: 0.25rem;
  }
  
  .move-button {
    background-color: var(--accent-color);
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-decoration: none;
    font-size: 0.9rem;
    align-self: stretch; /* 버튼 세로 길이 늘림 */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px; /* 좌우 패딩 증가 */
  }
  
  .move-button:hover {
    background-color: var(--secondary-color);
  }
  
  .close-button {
    display: block;
    margin: 1rem auto 0;
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 1rem;
  }
  
  .close-button:hover {
    background-color: var(--secondary-color);
  }
  
  .notification {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    z-index: 1000;
  }
  
  .schedule-link, .move-button {
    cursor: pointer;
    background-color: var(--accent-color);
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .schedule-link:hover, .move-button:hover {
    background-color: var(--secondary-color);
  }

  @media (max-width: 768px) {
    .course-external-link-popup {
      padding: 1rem;
    }
  
    .schedule-item {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .move-button {
      margin-top: 0.5rem;
      align-self: flex-end;
    }
  }