.course-page {
  padding-top: 60px; /* Adjust this value based on your header height */
}

.top-banner {
  background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
  color: white;
  padding: 60px 20px;
  text-align: center;
}

.top-banner .desc {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.top-banner h1 {
  font-size: 2.5rem;
  margin: 0;
}

.full-content {
  margin: 0 auto;
  background-color: var(--background-color);
}

.content{
  margin: 0 auto;
  max-width:1200px;
  padding: 20px 40px;
}

/* Responsive design */
@media (max-width: 768px) {
  .content{
    padding: 20px 20px;
  }

  .top-banner {
    padding: 40px 20px;
  }

  .top-banner .desc {
    font-size: 1rem;
  }

  .top-banner h1 {
    font-size: 1.8rem;
  }
}