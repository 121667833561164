.course-list {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.section-title {
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: var(--primary-color);
}

.course-item {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.course-image {
  flex: 0 0 30%;
  height: 500px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.course-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 1s ease;
  border-radius: 1rem;
}

.course-image:hover img {
  transform: scale(1.05);
}

.course-targets {
  position: absolute;
  top: 84px;
  left: 10px;
  display: flex;
  flex-wrap: wrap;
}

.target-tag {
  background-color: var(--accent-color);
  color: white;
  padding: 5px 10px;
  margin: 2px;
  border-radius: 4px;
  font-size: 0.9rem;
}

.course-details {
  flex: 1;
  padding: 30px;
  display: flex;
  flex-direction: column;
}

.course-oneline {
  display: flex;
  align-items: center;
  width: 100%;
}

.course-title {
  font-size: 2rem;
  margin-top: 0;
  margin-bottom: 0px;
  flex: 7;
  margin-right: 10px; /* 제목과 버튼 사이의 간격 */
  color: var(--primary-color);
}

.course-description-l {
  font-size: 1.1rem;
  margin-bottom: 20px;
}

.course-schedules {
  margin-top: 10px;
  padding: 10px 10px 0px;
  background-color: antiquewhite;
  align-items: center;
  border-radius: 1rem;
}

.schedule-item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 0.9rem;
  cursor: pointer;
}

.schedule-item span:first-child {
  margin-right: 15px;
  font-weight: bold;
}

.schedule-item span:nth-child(2) {
  flex-grow: 1;
  text-align: left;
  margin-right: 15px;
}

.notification {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 1000;
}

.schedule-link,
.calendar-button {
  flex-shrink: 0; /* 버튼 크기 유지 */
}

.schedule-link,
.move-button {
  cursor: pointer;
  background-color: var(--accent-color);
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.schedule-link:hover,
.move-button:hover {
  background-color: var(--secondary-color);
}

.calendar-button {
  background-color: var(--secondary-color);
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.9rem;
  white-space: nowrap;
}

.calendar-button:hover {
  background-color: var(--secondary-color);
  opacity: 80%;
  transform: translateY(-1px);
}

.course-info {
  padding: 10px;
  margin-bottom: 10px;
}

.info-item {
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.info-item strong {
  margin-right: 10px;
  white-space: nowrap;
}

.info-item span {
  margin-right: auto;
}

.map-button {
  background-color: var(--accent-color);
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  font-size: 0.9rem;
  margin-left: 10px;
  white-space: nowrap;
}

.map-button:hover {
  background-color: var(--accent-color);
  opacity: 80%;
  transform: translateY(-1px);
}

.apply-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1.2rem;
  width: 100%;
  margin-top: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  flex: 2;
}

.apply-button:hover {
  background-color: var(--primary-color);
  opacity: 80%;
  transform: translateY(-1px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.apply-button:active {
  transform: translateY(1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.upcoming {
  opacity: 0.7;
}

@media (max-width: 1024px) {
  .course-item {
    flex-direction: column;
    padding: 20px;
  }

  .course-image {
    flex: 0 0 auto;
    height: 300px;
    width: 100%;
  }

  .course-details {
    width: 100%;
  }

  .course-schedules {
    margin-bottom: 10px;
  }

  .schedule-item {
    display: flex;
    align-items: center;
    gap: 10px; /* 버튼 간 간격 추가 */
  }

  .schedule-item span {
    margin-bottom: 5px;
  }

  .course-targets {
    top: 10px;
  }
}

@media (max-width: 768px) {
  .course-oneline {
    flex-direction: column;
  }

  .apply-button {
    margin-top: 10px;
  }

  .course-list {
    padding: 0px;
  }

  .course-item {
    padding: 5px;
  }

  .section-title {
    font-size: 2rem;
  }

  .course-title {
    font-size: 1.5rem;
  }

  .info-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .info-item strong {
    margin-bottom: 5px; /* 텍스트 요소 간 여백 추가 */
  }

  .map-button {
    display: block;
    margin-left: 0;
    margin-top: 5px; /* 위쪽 여백 추가 */
    width: auto; /* 너비 자동 설정 */
  }

  .info-item .location {
    width: 100%; /* 부모 요소가 전체 너비를 차지하도록 설정 */
  }
}
