.lecture-slider {
  position: relative;
  width: 100%;
  height: 250px;
  overflow: hidden;
  touch-action: pan-y;
}

.slider-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  transition: transform 0.3s ease;
}

.slide {
  flex: 0 0 30%;
  height: 80%;
  margin: 0 10px;
  transition: all 0.3s ease;
  opacity: 0.5;
  position: relative;
  cursor: pointer; /* 모든 슬라이드에 cursor: pointer 추가 */
}

.slide.active {
  flex: 0 0 35%;
  height: 100%;
  opacity: 1;
  cursor: pointer; /* 강조된 슬라이드에도 cursor: pointer 추가 */
}

.slide img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
}

.slider-counter {
  position: absolute;
  bottom: 10px; /* 이 값을 -30px에서 -15px로 변경했습니다 */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 14px;
}

@media (max-width: 768px) {
  .section-content h2{
    margin-bottom: 20px;
  }

  .lecture-slider{
    height: 210px;
    overflow:visible;
  }

  .slide {
    flex: 0 0 80%;
    height: 210px;
  }

  .slide.active {
    flex: 0 0 90%;
    height: 210px;
  }

  .slider-counter {
    bottom: 20px; /* 모바일에서는 더 위로 올립니다 */
  }
}
