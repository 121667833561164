.short-videos {
  margin: 50px 0;
}

.short-videos h2 {
  text-align: left;
  margin-bottom: 30px;
  color: var(--primary-color);
}

.video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
}

.video-thumbnail {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
  color: white;
  text-align: center;
  padding: 25px 0px 5px 0px;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.video-thumbnail iframe {
  width: 100%;
  aspect-ratio: 16 / 9;
  border: none;
}

.video-thumbnail h3 {
  margin: 10px 0 0;
  font-size: 14px;
  text-align: center;
  color: var(--text-color);
}

.video-thumbnail:hover {
  transform: scale(1.05);
}

@media (max-width: 1200px) {
  .video-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .video-grid {
    grid-template-columns: 1fr;
  }
}