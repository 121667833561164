.banner-suggestion {
  background-image: url("/public/images/banner-background.webp");
  background-size: cover;
  background-position: top;
  padding: 40px 20px;
  text-align: center;
  color: var(--primary-color);
  position: relative;
  font-weight:700;
}

.banner-suggestion::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 51, 102, 0); /* Reduced opacity for blue tone */
  z-index: 1;
}

.banner-suggestion p {
  font-size: 24px;
  margin: 0 0 20px;
  position: relative;
  z-index: 2;
}

.suggest-btn {
  background-color: var(--secondary-color);
  color: #ffffff;
  border: none;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
  z-index: 2;
}

.suggest-btn:hover {
  background-color: #e39046;
}
