.login-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: var(--background-color);
  }
  
  .login-title {
    color: var(--primary-color);
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
  }
  
  .login-subtitle {
    color: var(--secondary-color);
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }