.legal-container {
    max-width: 1100px;
    margin: 40px auto;
    padding: 60px 20px 40px;
    font-family: 'Noto Sans KR', sans-serif;
  }
  
  .legal-container h1 {
    font-size: 28px;
    color: #003366;
    margin-bottom: 30px;
  }
  
  .legal-content h2 {
    font-size: 20px;
    color: #003366;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  
  .legal-content p {
    font-size: 16px;
    line-height: 1.6;
    color: #333;
    margin-bottom: 15px;
  }
  
  .legal-content ul {
    margin-bottom: 15px;
    padding-left: 20px;
  }
  
  .legal-content li {
    font-size: 16px;
    line-height: 1.6;
    color: #333;
    margin-bottom: 10px;
  }