body {
  font-family: 'Noto Sans KR', sans-serif;
  color: var(--text-color);
  margin: 0;
  padding: 0;
  line-height: 1.6;
}

.home {
  width: 100%;
}

.intro {
  background: linear-gradient(135deg, var(--primary-color), #000);
  background-size: cover;
  background-position: top;
  color: white;
  padding: 80px 0 60px;
}

.intro-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
}

.intro h1 {
  font-size: 4.5em;
  margin-bottom: 20px;
  color: #fff;
  animation: fadeInUp 1s ease-out;
}

.intro-message {
  padding-bottom: 15px;
}

.intro p {
  font-size: 1.5em;
  margin: 0px auto;
  color: #fff;
  font-weight: 400;
  animation: fadeInUp 1s ease-out 0.5s both;
}

.section-content,
.lecture-about-content,
.stark-introduction-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 20px;
}

.lecture-slider-section {
  background-color: #fff;
}

.lecture-about {
  background-color: var(--background-color);
}

.lecture-about-content h3 {
  color: #000;
  font-size: 1.5em;
  margin-bottom: 15px;
  font-weight: 400;
}

.image-ul-container {
  display: flex;
  align-items: flex-start;
  gap: 30px;
  margin-top: 30px;
}

.about-image {
  flex: 0 0 auto;
}

.about-image img {
  width: auto;
  height: 100%;
  max-height: 300px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.image-ul-container ul {
  flex: 1;
  padding-left: 20px;
}

.lecture-about li {
  margin-bottom: 15px;
  font-size: 1.1em;
}

h2 {
  color: var(--primary-color);
  margin-bottom: 30px;
  font-size: 1.5em;
  text-align: left;
}

.stark-introduction {
  background-color: var(--background-color);
}

.stark-introduction li{
  font-size:1.2em;
}

.color-secondary{
  color: var(--accent-color);
  font-weight: 700;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .intro h1 {
    font-size: 2.5em;
  }

  .intro p {
    font-size: 1em;
  }
  
  .lecture-slider-section{
    overflow:hidden;
  }

  .image-ul-container {
    flex-direction: column;
  }

  .about-image img {
    width: 100%;
    height: auto;
  }
  .lecture-about-content{
    padding: 30px 20px;
  }

  .lecture-about-content h3 {
    font-size: 1.3em;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .lecture-about li {
    font-size: 1em;
  }

  .stark-introduction li {
    font-size: 1em;
  }
}