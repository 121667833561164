@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;700&display=swap');

.footer {
  background-color: #003366;
  color: #ffffff;
  padding: 40px 0;
  font-family: 'Noto Sans KR', sans-serif;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
}

.footer-left, .footer-right {
  flex: 1;
}

.footer-left p, .footer-right p {
  margin: 5px 0;
  font-size: 14px;
}

.footer-links {
  margin-bottom: 20px;
}

.footer-links a {
  color: #f4a460;
  text-decoration: none;
  margin-right: 20px;
  font-size: 14px;
}

.footer-links a:hover {
  text-decoration: underline;
}

.copyright {
  font-size: 12px;
  color: #cccccc;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
  }

  .footer-left, .footer-right {
    text-align: center;
    margin-bottom: 20px;
  }

  .footer-links {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .footer-links a {
    margin: 5px 10px;
  }
}