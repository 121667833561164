.program-outlook {
  margin: 0 auto;
  padding: 2rem;
  max-width: 1280px;
}

.program-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.program-item {
  flex: 1;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin: 0 0.5rem;
  display: flex;
  flex-direction: column;
}

.program-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.program-icon {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 0.5rem;
}

.program-title {
  color: var(--primary-color);
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0.5rem;
  height: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.program-subtitle {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.subtitle-box {
  background-color: var(--accent-color);
  color: white;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  font-size: 0.8rem;
}

.program-items.items-count-1 .program-item-link {
  flex-basis: 100%;
}

.program-items.items-count-2 .program-item-link {
  flex-basis: calc(50% - 5px);
}

.program-items {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: 10px;
}

.program-item-link {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0.5rem;
  background-color: var(--secondary-color);
  color: white;
  text-decoration: none;
  border-radius: 4px;
  border:none;
  transition: background-color 0.3s;
  min-height: 30px; /* 최소 높이 설정 */
  font-family: 'Noto Sans KR', sans-serif;
}

.program-item-link:hover {
  background-color: var(--accent-color);
}

.footnote {
  width: 100%;
  font-size: 0.8rem;
  color: var(--text-color);
  text-align: center;
  padding-top: 1rem;
  border-top: 1px solid var(--secondary-color);
}

@media (max-width: 1024px) {
  .program-container {
    flex-wrap: wrap;
  }

  .program-item {
    flex-basis: calc(50% - 1rem);
    margin-bottom: 1rem;
  }
}

@media (max-width: 768px) {
  .program-item {
    flex-basis: 100%;
  }

  .program-items {
    flex-direction: column;
  }

  .program-item-link {
    min-height: auto; /* 모바일에서는 최소 높이 제거 */
  }
}
