.location-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

.location-title {
  color: var(--primary-color);
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  margin-top: 0px;
}

.location-content {
  display: flex;
  flex-direction: column;
}

.location-map {
  width: 100%;
}

.location-info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.address-info, .transportation-info, .parking-info {
  background-color: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.address-info p, .transportation-info p, .parking-info p {
  margin: 0.5rem 0;
  color: var(--text-color);
}

.address-info {
  display: flex;
  justify-content: space-around;
}

.transportation-info h3 {
  color: var(--secondary-color);
  margin-bottom: 1rem;
  margin-top: 0px;
}

.transport-item-group {
  display: flex;
  gap: 30px;
  align-items: center;
}

.transport-item {
  display: flex;
  margin-bottom: 1rem;
  gap: 20px;
  align-items: center;
  font-weight: 500;
}

.transport-item:first-child {
  flex: 2;
}

.transport-item:last-child {
  flex: 1;
}

.transport-icon {
  padding: 1px;
  background-color: var(--accent-color);
  mask-size: cover;
}

.bus-icon {
  mask-image: url('/public/images/bus-solid.svg');
  height: 30px;
  width: 52px;
}

.subway-icon {
  mask-image: url('/public/images/train-subway-solid.svg');
  height: 30px;
  width: 27px;
}

.parking-info {
  background-color: rgba(244, 164, 96, 0.1);
}

.parking-info strong {
  color: var(--accent-color);
}

@media (max-width: 1024px) {
  .bus-icon {
    height: 30px;
    width: 70px;
  }
}

@media (max-width: 767px) {
  .location-title {
    font-size: 1.5rem;
  }

  .address-info {
    flex-direction: column;
  }

  .transport-item-group {
    flex-direction: column;
  }

  .transport-item {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .transport-item:first-child,
  .transport-item:last-child {
    flex: 1;
  }

  .transport-icon {
    height: 24px;
  }

  .bus-icon {
    width: 26px;
  }

  .subway-icon {
    width: 22px;
  }

  .address-info p, .transportation-info p, .parking-info p {
    font-size: 0.9rem;
  }
}

@media (min-width: 768px) {
  .location-content {
    flex-direction: column;
    gap: 1rem;
  }

  .location-map {
    flex: 1;
  }

  .location-info {
    flex: 1;
  }
}