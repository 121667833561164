.program-detail {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Noto Sans KR', sans-serif;
  color: var(--text-color);
}

.course-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  margin-top: 4rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

.course-header img {
  max-width: 600px;
  height: auto;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.course-header h1 {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
  text-align: center;
}

.course-description {
  font-size: 1.1rem;
  line-height: 1.6;
  text-align: center;
  margin-bottom: 1.5rem;
}

.course-info-pd {
  width: 100%;
  background-color: var(--background-color);
  padding: 1rem;
  border-radius: 8px;
}

.course-info-pd p {
  margin: 0.5rem 0;
}

.schedules {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin-bottom: 2rem;
}

.schedules h2 {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.schedule-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #eee;
  transition: background-color 0.3s ease;
}

.schedule-item:hover {
  background-color: var(--background-color);
}

.schedule-item.past {
  opacity: 0.6;
  background-color: #f5f5f5;
}

.schedule-date, .schedule-time {
  font-weight: bold;
  color: var(--primary-color);
}

.schedule-content {
  flex-grow: 1;
  margin: 0 1rem;
}

.schedule-link {
  background-color: var(--accent-color);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.schedule-link:hover:not(.past) {
  background-color: var(--secondary-color);
}

.schedule-link.past {
  background-color: #ccc;
  cursor: not-allowed;
}

.show-more-button {
  display: block;
  margin: 1rem auto 0;
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.show-more-button:hover {
  background-color: var(--secondary-color);
}

.notification {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 1000;
}

.loading, .error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.5rem;
  color: var(--primary-color);
}

.error {
  color: red;
}

@media (max-width: 768px) {
  .course-header img {
    max-width: 340px;
  }

  .program-detail {
    padding: 10px;
  }

  .course-header {
    padding: 1rem;
  }

  .course-header h1 {
    font-size: 2rem;
  }

  .course-description {
    font-size: 1rem;
  }

  .schedules {
    padding: 1rem;
  }

  .schedules h2 {
    font-size: 1.5rem;
  }

  .schedule-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .schedule-date, .schedule-time, .schedule-content {
    margin-bottom: 0.5rem;
  }

  .schedule-link {
    align-self: center;
  }
}